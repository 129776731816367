import React, { useState, useEffect, useContext } from "react"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"

import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"

import { i18n } from "../../translate/i18n"
import api from "../../services/api"
import ButtonWithSpinner from "../ButtonWithSpinner"
import ContactModal from "../ContactModal"
import toastError from "../../errors/toastError"
import { AuthContext } from "../../context/Auth/AuthContext"
import {
    Grid,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
    online: {
        fontSize: 11,
        color: "#25d366",
    },
    offline: {
        fontSize: 11,
        color: "#e1306c",
    },
}))

const filter = createFilterOptions({
    trim: true,
})

const NewTicketModal = ({ modalOpen, onClose, initialContact }) => {
    const { user } = useContext(AuthContext)
    const classes = useStyles()
    const { companyId, whatsappId } = user
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchParam, setSearchParam] = useState("")
    const [selectedContact, setSelectedContact] = useState(null)
    const [selectedQueue, setSelectedQueue] = useState("")
    const [newContact, setNewContact] = useState({})
    const [contactModalOpen, setContactModalOpen] = useState(false)
    const [whatsapps, setWhatsapps] = useState([])
    const [selectedWhatsapp, setSelectedWhatsapp] = useState("")

    useEffect(() => {
        if (initialContact?.id !== undefined) {
            setOptions([initialContact])
            setSelectedContact(initialContact)
        }
    }, [initialContact])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                api.get(`/whatsapp`, {
                    params: { companyId, session: 0 },
                }).then(({ data }) => setWhatsapps(data))
            }

            if (whatsappId !== null && whatsappId !== undefined) {
                setSelectedWhatsapp(whatsappId)
            }

            if (user.queues.length === 1) {
                setSelectedQueue(user.queues[0].id)
            }
            fetchContacts()
            setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [])

    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false)
            return
        }
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    })
                    setOptions(data.contacts)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            fetchContacts()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, modalOpen])

    const handleClose = () => {
        onClose()
        setSearchParam("")
        setSelectedContact(null)
    }

    const handleSaveTicket = async (contactId) => {
        if (!contactId) return
        if (selectedQueue === "" && user.profile !== "admin") {
            toast.error("Selecione uma fila")
            return
        }
        setLoading(true)
        try {
            const queueId = selectedQueue !== "" ? selectedQueue : null
            const whatsappId = selectedWhatsapp !== "" ? selectedWhatsapp : null

            const { data: ticket } = await api.post("/tickets", {
                contactId: contactId,
                queueId,
                whatsappId,
                userId: user.id,
                status: "open",
            })
            onClose(ticket)
        } catch (err) {
            toastError(err)
        }
        setLoading(false)
    }

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue)
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name })
            setContactModalOpen(true)
        }
    }

    const handleCloseContactModal = () => {
        setContactModalOpen(false)
    }

    const handleAddNewContactTicket = (contact) => {
        handleSaveTicket(contact.id)
    }

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params)

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            })
        }

        return filtered
    }

    const renderOption = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`
        } else {
            return `${i18n.t("newTicketModal.add")} ${option.name}`
        }
    }

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`
        } else {
            return `${option.name}`
        }
    }

    const renderContactAutocomplete = () => {
        if (initialContact === undefined || initialContact.id === undefined) {
            return (
                <Grid xs={12} item>
                    <Autocomplete
                        fullWidth
                        options={options}
                        loading={loading}
                        clearOnBlur
                        autoHighlight
                        freeSolo
                        clearOnEscape
                        getOptionLabel={renderOptionLabel}
                        renderOption={renderOption}
                        filterOptions={createAddContactOption}
                        onChange={(e, newValue) =>
                            handleSelectOption(e, newValue)
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={i18n.t("newTicketModal.fieldLabel")}
                                variant="outlined"
                                autoFocus
                                onChange={(e) => setSearchParam(e.target.value)}
                                onKeyPress={(e) => {
                                    if (loading || !selectedContact) return
                                    else if (e.key === "Enter") {
                                        handleSaveTicket(selectedContact.id)
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            )
        }
        return null
    }

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContactTicket}
            ></ContactModal>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("newTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid style={{ width: 300 }} container spacing={2}>
                        {renderContactAutocomplete()}
                        <Grid xs={12} item>
                            <Select
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                value={selectedQueue}
                                onChange={(e) => {
                                    setSelectedQueue(e.target.value)
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                renderValue={() => {
                                    if (selectedQueue === "") {
                                        return "Selecione uma fila"
                                    }
                                    const queue = user.queues.find(
                                        (q) => q.id === selectedQueue
                                    )
                                    return queue.name
                                }}
                            >
                                {user.queues?.length > 0 &&
                                    user.queues.map((queue, key) => (
                                        <MenuItem
                                            dense
                                            key={key}
                                            value={queue.id}
                                        >
                                            <ListItemText
                                                primary={queue.name}
                                            />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>

                        {/* CONEXAO */}
                        <Grid xs={12} item>
                            <Select
                                required
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                value={selectedWhatsapp}
                                onChange={(e) => {
                                    setSelectedWhatsapp(e.target.value)
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                renderValue={() => {
                                    if (selectedWhatsapp === "") {
                                        return "Selecione uma Conexão"
                                    }
                                    const whatsapp = whatsapps.find(
                                        (w) => w.id === selectedWhatsapp
                                    )
                                    return whatsapp.name
                                }}
                            >
                                {whatsapps?.length > 0 &&
                                    whatsapps.map((whatsapp, key) => (
                                        <MenuItem
                                            dense
                                            key={key}
                                            value={whatsapp.id}
                                        >
                                            <ListItemText
                                                primary={
                                                    <>
                                                        {/* {IconChannel(whatsapp.channel)} */}
                                                        <Typography
                                                            component="span"
                                                            style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                    "10px",
                                                                display:
                                                                    "inline-flex",
                                                                alignItems:
                                                                    "center",
                                                                lineHeight: "2",
                                                            }}
                                                        >
                                                            {whatsapp.name}{" "}
                                                            &nbsp;{" "}
                                                            <p
                                                                className={
                                                                    whatsapp.status ===
                                                                    "CONNECTED"
                                                                        ? classes.online
                                                                        : classes.offline
                                                                }
                                                            >
                                                                (
                                                                {
                                                                    whatsapp.status
                                                                }
                                                                )
                                                            </p>
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("newTicketModal.buttons.cancel")}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContact}
                        onClick={() => handleSaveTicket(selectedContact.id)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t("newTicketModal.buttons.ok")}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default NewTicketModal
